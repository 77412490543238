<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀参加西部证券2023年度资本市场峰会
              </p>
              <div class="fst-italic mb-2">2022年12月1日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >西部证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/23/01-西部证券.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    12月1日，非凸科技联合创始人李佐凡受邀参加线上举办的“动之徐生——西部证券2023年度资本市场峰会”，在分论坛“量化投资如何融入主动思维”上发表了主题演讲。李佐凡从机器学习的角度来分享量化投资的技术应用，干货满满，精彩纷呈！
                  </p>
                   <p>
                    机器学习作为传统量化的补充与技术革新，不仅使得对海量数据的分析应用成为可能，还可以捕捉传统量化中难以发现的数据之间的潜在关系。在量化投资领域，利用机器学习技术在一定条件下能够更好的完成资产收益预测、风险建模、组合优化、算法交易等任务。
                  </p>
                  <p>
                    量化投资的核心是数字分析、规律总结，然后利用统计的方法找到投资的理念或策略。李佐凡先通过举例巴菲特的价值投资来对比量化投资，并表示，“如果用量化的方法来帮助巴菲特进行价值投资，那么收益率和稳定性可能会更好。”随后通过举例常见的机器学习方法“卷积神经网络（CNN）”、“迁移学习（Transfer learning）”，来讲述如何通过机器学习来预测未来股票价格，以及预测模型是否可以运用到其他国家股票。
                  </p>
                  <p>
                    机器学习不仅在高频交易中得到广泛应用，还在德州扑克、AlphaGo、AlphaStar、OpenAI Five等场景得到很好应用效果。李佐凡说，“做投资决策时，不仅要基于对市场的预测，也要基于对投资对手的预测，这一点至关重要。”
                  </p>
                  <p>
                    把AI技术应用到量化投资领域，已成为行业共识的发展趋势。非凸科技把握行业发展路径，结合机器学习等新兴技术，持续升级迭代算法能力，高度优化交易执行，显著提高客户收益。
                  </p>
                  <p>
                    未来，非凸科技也将继续以客户为中心，为金融机构提供更优质的产品和服务，持之以恒，砥砺创新！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News23",
};
</script>

<style></style>
